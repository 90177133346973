import React from 'react';

const MySVGComponent = ({ }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.573 36.573">
    <g id="ios-checkmark-circle-outline" transform="translate(0.5 0.5)">
      <path
        id="Path_72"
        data-name="Path 72"
        d="M166.546,175.267l-1.491-1.563a.318.318,0,0,0-.237-.1.3.3,0,0,0-.237.1l-10.335,10.614-3.761-3.835a.323.323,0,0,0-.474,0l-1.508,1.537a.349.349,0,0,0,0,.492l4.744,4.837a1.489,1.489,0,0,0,.991.492,1.561,1.561,0,0,0,.983-.475h.008l11.326-11.608A.375.375,0,0,0,166.546,175.267Z"
        transform="translate(-139.729 -162.93)"
      />
      <path
        id="Path_73"
        data-name="Path 73"
        d="M65.786,50.394A15.386,15.386,0,1,1,54.9,54.9a15.29,15.29,0,0,1,10.886-4.506m0-2.394A17.786,17.786,0,1,0,83.573,65.786,17.784,17.784,0,0,0,65.786,48Z"
        transform="translate(-48 -48)"
      />
    </g>
  </svg>
);

export default MySVGComponent;
