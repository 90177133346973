import React, { useContext, useEffect, createContext, useState } from 'react'
import cn from 'classnames'
import { OperaContext } from '@/lib/operaFrame/OperaProvider'

import avatar from '@/assets/images/ari_minimized.webp'

import * as styles from './styles.module.scss'
import constants from '@/core/utils/constants'

export const Com = ({ message, type, idx, ...props }) => {
  const { chatMessage } = message || {}

  switch (type) {
    case constants.messageTypes.HTMLPANEL:
      return <BubbleHtml message={chatMessage} />
    case constants.messageTypes.BUBBLE:
    default:
      return <BubbleWithAvatar message={chatMessage} />
  }
}

const BubbleWithAvatar = ({ message }) => {
  const {
    //

    client
  } = useContext(OperaContext)
  const clientChatAvatar = client?.client_chat_avatar ? `${client?.client_chat_avatar}?v=${client.updatedAt}` : avatar

  return (
    <div className={cn(styles.bubbleContainer)}>
      <div className={cn(styles.avatar)}>
        <img src={clientChatAvatar} alt="avatar" />
      </div>
      <div className={cn(styles.bubble)} dangerouslySetInnerHTML={{ __html: message }} />
    </div>
  )
}

const BubbleHtml = ({ message }) => {
  // TODO: add html panel css
  return <div dangerouslySetInnerHTML={{ __html: message }} />
}

export default Com
