import React, { useEffect, useRef, useContext, createContext, useState } from 'react'
import _ from 'lodash'
import cn from 'classnames'
import { OperaContext } from '@/lib/operaFrame/OperaProvider'
import Messages from '@/organism/Messages'
import InputZone from '@/organism/InputZone'

import * as styles from './styles.module.scss'

export default ({ className, ...rest }) => {
  const {
    tree,
    //
  } = useContext(OperaContext)

  const containerRef = useRef(null)
  const inputRef = useRef(null)
  const modalRef = useRef(null)

  useEffect(() => {
    if (modalRef.current) {
      const modalElement = modalRef.current.querySelector('.message_scroll')
      const inputElement = inputRef.current

      const handleScroll = event => {
        const { scrollTop, scrollHeight, clientHeight } = modalElement
        // prevent scroll up, but allow scroll down
        if (scrollTop === 0 && event.deltaY < 0) event.preventDefault()

        // prevent scroll down, but allow scroll up
        if (scrollTop + clientHeight >= scrollHeight && event.deltaY > 0) event.preventDefault()
      }

      const handleTouchMove = event => {
        if (!event.cancelable) return
        const { scrollTop, scrollHeight, clientHeight } = modalElement
        const touch = event.touches[0]

        // prevent scroll up, but allow scroll down
        if (scrollTop === 0 && touch.clientY > touchStartY) event.preventDefault()

        // prevent scroll down, but allow scroll up
        if (scrollTop + clientHeight >= scrollHeight && touch.clientY < touchStartY) event.preventDefault()
      }

      const handleTouchStart = event => {
        touchStartY = event.touches[0].clientY
      }

      let touchStartY = 0

      modalElement.addEventListener('wheel', handleScroll)
      modalElement.addEventListener('touchstart', handleTouchStart)
      modalElement.addEventListener('touchmove', handleTouchMove)

      // handle browser deprecated event causes scroll issue
      const observer = patchOnScrollIssue(modalElement)
      const observerInput = patchOnScrollIssue(modalElement, inputElement)

      return () => {
        modalElement.removeEventListener('wheel', handleScroll)
        modalElement.removeEventListener('touchstart', handleTouchStart)
        modalElement.removeEventListener('touchmove', handleTouchMove)

        // handle browser deprecated event causes scroll issue
        if (observer) {
          observer.disconnect()
        }
        if (observerInput) {
          observerInput.disconnect()
        }
      }
    }
  }, [modalRef.current])

  if (!tree) return null

  // print current scroll height
  return (
    <div ref={modalRef} style={{ height: '100%' }}>
      <div className={cn(className, styles.root)}>
        <div className={cn(styles.chatbox)}>
          <div className={cn('message_scroll', styles.messages)} ref={containerRef}>
            <Messages>
              <div className={cn(styles.inputzone)} ref={inputRef}>
                <InputZone />
              </div>
            </Messages>
          </div>
        </div>
      </div>
    </div>
  )
}

const patchOnScrollIssue = (modalElement, monitorElement) => {
  // Callback function to handle mutations
  const mutationCallback = mutationsList => {
    for (let mutation of mutationsList) {
      if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
        // modalElement.scrollTo({
        //   top: modalElement.scrollHeight,
        //   behavior: 'smooth'
        // });
        smoothScroll(modalElement, modalElement.scrollHeight, 500)
      }
    }
  }

  // Create a MutationObserver instance and pass the callback function
  const observer = new MutationObserver(mutationCallback)
  if (monitorElement || modalElement) {
    observer.observe(monitorElement || modalElement, { childList: true, subtree: true, characterData: true })
  }

  // Cleanup function to disconnect the observer when the component unmounts
  return observer
}

function smoothScroll(element, target, duration) {
  const start = element.scrollTop
  const distance = target - start
  let startTime = null

  function animation(currentTime) {
    if (startTime === null) startTime = currentTime
    const timeElapsed = currentTime - startTime
    const run = ease(timeElapsed, start, distance, duration)
    element.scrollTop = run
    if (timeElapsed < duration) requestAnimationFrame(animation)
  }

  function ease(t, b, c, d) {
    t /= d / 2
    if (t < 1) return (c / 2) * t * t + b
    t--
    return (-c / 2) * (t * (t - 2) - 1) + b
  }

  requestAnimationFrame(animation)
}
