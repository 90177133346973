import React, { useContext, useEffect, createContext, useState } from 'react'
import cn from 'classnames'
import helper from '@/core/utils/helper'
import { OperaContext } from '@/lib/operaFrame/OperaProvider'
import useUser from '@/lib/operaFrame/operaHooks/useUser'
import Input_Text from '@/atom/Input_Text'

import * as styles from './styles.module.scss'

export default ({ className, ...rest }) => {
  const {
    tree,
    labels,
    stage,
    onError,
    country = 'US'
    //
  } = useContext(OperaContext)

  const [value, setValue] = useState('')
  // const { options } = tree[stage?.currentStep] || {}
  const { onProcessInput } = useUser()
  const handleChange = (e) => {
    setValue(e.target.value)
  }
  const handleApply = () => {
    if (!value) {
      onError(labels['lbl_Invalid_ZipCode'])
      return
    }

    // validate zip
    const regexZip = helper.regexForZips[country].regex
    const regexResult = new RegExp(regexZip)
    if (!regexResult.test(value)) {
      onError(labels['lbl_Invalid_ZipCode'] || helper.regexForZips[country].message)
      return
    }

    onProcessInput(
      {
        zip: value
      },
      value
    )
  }

  
  return (
    <div className={cn(className, styles.root)}>
      <Input_Text {...{ 
        onChange: handleChange, 
        onApply: handleApply,
        value
        }} />
    </div>
  )
}
