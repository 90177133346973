@use "source/webComponent/theme" as *;

.bubbleContainer {
  margin-bottom: $spacing_message_gap;
  padding: 0 $spacine_px;
  display: flex;
  gap: $spacine_px_sm;
  justify-content: flex-end;
  align-items: flex-start;
  box-sizing: border-box;

  .avatar {
    display: inline-block;
    order: 0;
    flex-shrink: 0;
    padding-top: $spacine_px_sm;
    img {
      object-fit: contain;
      background-color: $color_avatar_background;
      width: 40px;
      height: 40px;
      border-radius: 50% 50% 50% 0;
    }
    @include animatedElement(left);
  }

  .bubble {
    max-width: 380px;
    background-color:$warmWhite;

    border-radius: $spacing_message_radius;
    padding: $spacine_px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: inline-block;
    font-size: 14px;
    color: $color_text;

    @include animatedElement(right);
  }
}


