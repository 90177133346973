@use "source/webComponent/theme" as *;

.header {
  min-height: 60px;
  border-bottom: 1px solid $color_border;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: $spacine_px_sm;
  gap: $spacine_px * 2;

  @include breakpoint-up(sm) {
    flex-direction: row;
  }

  .logo_container {
    width: 100%;
    img {
      height: 100%;
      max-height: 120px;
      max-width: 160px;
    }
  }
  .title {
    font-size: 16px;
    font-weight: 600;
    color: $color_primary;

    // change line if \n
    white-space: pre-line;
  }
  .titleContainer {

    a.website {
      font-size: 14px;
      color: $color_link;
      font-weight: normal;
      display: block;
      padding: $spacine_px 0;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
        opacity: 0.8;
      }
    }
  }
}

.body {
  padding-top: $spacine_px;
  display: flex;
  flex-direction: column;
  gap: $spacine_px;
  line-height: 24px;
  color: $color_textLight;

  a {
    color: $color_link;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      opacity: 0.8;
    }
  }

  .phone {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $spacine_px;
  }

  .phone_miles {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .address {

    .navigate {
      margin-left: $spacine_px;
    }
  }
  .payments,  .services {
    ul {
      padding: 0;
      margin: 0;
      li {
        padding-left: $spacine_px;
        margin-bottom: $spacine_px;

        display: flex;
        flex-direction: row;
        align-items: center;
        img {
          height: 10px;
          width: 10px;
          margin-right: $spacine_px;
        }
      }
    }
  }

  .title {
    font-size: 16px;
    font-weight: 300;
    color: $color_primary;
    line-height: 30px;
    margin-bottom: $spacine_px_sm;
  }

  .icon {
    height: 16px;
    width: 16px;
    fill: $color_primary;
    margin-right: $spacine_px;
  }
}

