import React, { useEffect, useContext } from 'react'
import Api from '@/provider/Api'
import _ from 'lodash'

const Hook = () => {
  const getImage = (fileName, type = "facilities") => {
    if (!fileName) return null
    return `${Api.CND_SERVER}/images/${type}/${fileName}`
  }
  return {
    getImage
  }
}

export default Hook
