import React, { useRef, useContext, useEffect, useState } from 'react'
import cn from 'classnames'
import { OperaContext } from '@/lib/operaFrame/OperaProvider'
import IconExclamation from '@/assets/icons/IconExclamation'

import * as styles from './styles.module.scss'

const STAGE_DELAY = 3000

export const Com = ({ message = {}, slId, step, idx, ...props }) => {
  const { onCancelSpeakLine } = useContext(OperaContext)
  const { chatMessage, messageType = 'error' } = message || {}

  const [isHovering, setIsHovering] = useState(false)
  const [remainingTime, setRemainingTime] = useState(STAGE_DELAY)
  const [startTime, setStartTime] = useState(Date.now())

  const timeRef = useRef(null)
  useEffect(() => {
    if (!isHovering) {
      timeRef.current = setTimeout(() => {
        onCancelSpeakLine(slId)
      }, remainingTime)

      // Update start time when timer starts
      setStartTime(Date.now())
    }

    if (isHovering) {
      // Calculate remaining time if mouse is hovering and component unmounts or pauses
      setRemainingTime(oldTime => Math.max(0, oldTime - (Date.now() - startTime)))
      clearTimeout(timeRef.current)
    }

    return () => {
      /* 
      ISSUE HERE: 
        if multiple messages, will trigger all at once 
        because after cancel, the array shuffles, object renewed, so it rerenders component.
        before that, it will call "return"
      */
      onCancelSpeakLine(slId)
      clearTimeout(timeRef.current)
    }
  }, [isHovering])

  const handleMouseEnter = () => {
    setIsHovering(true)
  }

  const handleMouseLeave = () => {
    setIsHovering(false)
  }

  return (
    <div className={cn(styles.root)} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className={cn(styles.message, styles?.[messageType] || '')}>
        <div className={cn(styles.icon)}>
          <IconExclamation />
        </div>
        <span>{chatMessage}</span>
      </div>
    </div>
  )
}

export default Com
