import React, { useContext, useEffect, createContext, useState } from 'react'
import cn from 'classnames'
import { OperaContext } from '@/lib/operaFrame/OperaProvider'
import useUser from '@/lib/operaFrame/operaHooks/useUser'

import List_Options from '@/atom/List_Options'

import * as styles from './styles.module.scss'

export default ({ className, ...rest }) => {
  const {
    tree,
    labels,
    stage,
    //
  } = useContext(OperaContext)

  const { options } = tree[stage.currentStep] || {}
  const { onProcessOption } = useUser()
  const handleApply = (...params) => {
    onProcessOption(...params)
  }

  return (
    <div className={cn(className, styles.root)}>
      <List_Options {...{ options, onClick: handleApply }} />
    </div>
  )
}
