import { useCallback, useContext } from 'react'
import Api from '@/provider/Api'
import { GeneralContext } from '@/provider/GeneralProvider'

function useEngagement(onClick) {
  const {
    client,
    token,
    visitorId,
    language,
    isPreview
    //
  } = useContext(GeneralContext)

  return useCallback(
    (recordType, id, link) => {
      return e => {
        // record engagement
        const activity_type = recordType
        const activity_code = [id, link].filter(a => a).join('::')


        const o = {
          visitor_id: visitorId,
          activity_type,
          activity_code,
          language,
          // country,
          isPreview,
        }

        // dont await (to speed up)
        Api.recordEngagement(o)

        if (onClick) {
          onClick(e)
        }
      }
    },
    [onClick]
  )
}

export default useEngagement
