import React from 'react'

const MySVGComponent = (props) => (
  <svg
    id="icon_"
    data-name="icon / !"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 20 19"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <ellipse
          id="Mask"
          cx="10"
          cy="9.5"
          rx="10"
          ry="9.5"
        />
      </clipPath>
    </defs>
    <g id="_" data-name="!">
      <ellipse
        id="Mask-2"
        data-name="Mask"
        cx="10"
        cy="9.5"
        rx="10"
        ry="9.5"
      />
      <g id="_2" data-name="!" clipPath="url(#clip-path)">
        <path
          id="_3"
          data-name="!"
          d="M0,10.71V8.4H2.355v2.31ZM.63,7.5.06,2.88V0H2.28V2.88L1.695,7.5Z"
          transform="translate(8.83 4.24)"
          fill="#ffead8"
        />
      </g>
    </g>
  </svg>
)

export default MySVGComponent
