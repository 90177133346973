import React, { useContext, useRef, createContext, useState, useEffect } from 'react'
import _ from 'lodash'
import cn from 'classnames'
import { GeneralContext } from '@/provider/GeneralProvider'
import * as styles from './styles.module.scss'

export default React.forwardRef(({ isShow, children, className, ...rest }, ref) => {
  const {
    uiDrawer,
    zIndex
    //
  } = useContext(GeneralContext)
  const modalRef = useRef(null)

  useEffect(() => {
    if (modalRef.current) {
      const modalElement = modalRef.current
      const handleScroll = event => {
        const { scrollTop, scrollHeight, clientHeight } = modalElement
        // prevent scroll up, but allow scroll down
        if (scrollTop === 0 && event.deltaY < 0) event.preventDefault()

        // prevent scroll down, but allow scroll up
        if (scrollTop + clientHeight >= scrollHeight && event.deltaY > 0) event.preventDefault()
      }

      const handleTouchMove = event => {
        if (!event.cancelable) return;
        const { scrollTop, scrollHeight, clientHeight } = modalElement
        const touch = event.touches[0]

        // prevent scroll up, but allow scroll down
        if (scrollTop === 0 && touch.clientY > touchStartY) event.preventDefault()

        // prevent scroll down, but allow scroll up
        if (scrollTop + clientHeight >= scrollHeight && touch.clientY < touchStartY) event.preventDefault()
      }

      const handleTouchStart = event => {
        touchStartY = event.touches[0].clientY
      }

      let touchStartY = 0
      // get the modal element
      
      modalElement.addEventListener('wheel', handleScroll)
      modalElement.addEventListener('touchstart', handleTouchStart)
      modalElement.addEventListener('touchmove', handleTouchMove)

      return () => {
        modalElement.removeEventListener('wheel', handleScroll)
        modalElement.removeEventListener('touchstart', handleTouchStart)
        modalElement.removeEventListener('touchmove', handleTouchMove)
      }
    }
  }, [modalRef.current])

  return (
    <div
      className={cn(className, styles.root, uiDrawer ? styles.show : styles.hide)}
      {...rest}
      style={{ zIndex: zIndex + 1 }}
    >
      <div className={cn(styles.ModalScrollContainer)} ref={modalRef}>
        {children}
      </div>
    </div>
  )
})
