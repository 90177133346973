import React, { useContext, createContext, useState } from 'react'
import cn from 'classnames'
import { OperaContext } from '@/lib/operaFrame/OperaProvider'
import constants from '@/core/utils/constants'

import Message_Bot from '@/molecule/Message_Bot'
import Message_User from '@/molecule/Message_User'
import Message_System from '@/molecule/Message_System'
import Message_StageManager from '@/molecule/Message_StageManager'

import * as styles from './styles.module.scss'

export default ({ className, children, ...rest }) => {
  const {
    labels,
    dialogueHistory
    //
  } = useContext(OperaContext)

  return (
    <>
      {dialogueHistory?.map((item, idx) => {
        const { character } = item
        let jsxDisplay = null

        const props = {
          ...item,
          idx,
        }

        switch (character) {
          case constants.characters.BOT:
            jsxDisplay = <Message_Bot {...props} />
            break
          case constants.characters.USER:
            jsxDisplay = <Message_User {...props} />
            break
          case constants.characters.SYSTEM:
            jsxDisplay = <Message_System {...props} />
            break
          case constants.characters.STAGEMANAGER:
            jsxDisplay = <Message_StageManager {...props} />
            break
          default:
            break
        }
        return (
          <div key={`message_${idx}`} className={cn(styles.message)}>
            {jsxDisplay}
          </div>
        )
      })}
      {children}
    </>
  )
}
