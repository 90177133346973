import React, { useContext, useEffect, createContext, useState } from 'react'
import cn from 'classnames'
import { OperaContext } from '@/lib/operaFrame/OperaProvider'
import noUserAvatar from '@/assets/images/no-avatar.webp'

import * as styles from './styles.module.scss'

export const Com = ({ message = {}, step, idx, ...props }) => {
  const {
    //
  } = useContext(OperaContext)

  const { chatMessage } = message || {}

  let displayMessage = ''

  switch (typeof chatMessage) {
    case 'string':
      displayMessage = chatMessage
      break
    case 'function':
      displayMessage = chatMessage()
      break
    default:
      displayMessage = 'No message'
      break
  }

  return <BubbleWithAvatar message={displayMessage} />
}

const BubbleWithAvatar = ({ message }) => {
  const {
    //
    userAvatar
  } = useContext(OperaContext)

  return (
      <div className={cn(styles.bubbleContainer)}>
        <div className={cn(styles.bubble)} dangerouslySetInnerHTML={{ __html: message }} />
        <div className={cn(styles.avatar)}>
          <img src={userAvatar || noUserAvatar} />
        </div>
      </div>
  )
}

export default Com
