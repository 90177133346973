@use "source/webComponent/theme" as *;
.root {
  font-family: HelveticaNeue, Arial, sans-serif, monospace;
  height: 100%;
  box-sizing: border-box;
  background-color: $color_background;
  line-height: $spacing_lineheight;

  .chatbox {

    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    
    
    .messages {
      flex: 1;
      overflow-y: auto; 
      background-color: $color_background;

      & > :first-child {
        margin-top: auto;
      }

      box-sizing: border-box;
      flex-flow: column;
      flex-grow: 1;
      margin-top: 0;
      display: flex;
      height: 100%;


      &::-webkit-scrollbar {
        width: 4px;
      }
    
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      }
    
      &::-webkit-scrollbar-thumb {
        background-color: $color_border;
        border-radius: $spacing_borderradius;
      }
    
      @include breakpoint-up(xl) {
        &::-webkit-scrollbar {
          width: 6px;
        }
      }
    }
    .inputzone {
      border-top: 1px solid $color_border;
      z-index: 100;
      position: sticky;
      bottom: 0;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
}