


import React, { useContext, useEffect, createContext, useState } from 'react'
import _ from 'lodash'
import cn from 'classnames'
import constants from '@/core/utils/constants'
import { OperaContext } from '@/lib/operaFrame/OperaProvider'

import Layout_Resource from '@/molecule/Layout_Resource'

import List_Options from '@/atom/List_Options'

import * as styles from './styles.module.scss'

export const Com = ({ message = {}, step, idx, type, ...props }) => {
  const {
    labels,
  } = useContext(OperaContext)
  const { data = [], errorMessage, isMessageLoading } = message

  if (isMessageLoading) {
    return (
      <div className={cn(styles.root, styles.loading)}>
         <div>{labels['lbl_Loading'] || 'Loading...'}</div>
      </div>
    )
  }

  if (errorMessage) {
    return (
      <div className={cn(styles.root, styles.error)}>
        <div>
          <b>{errorMessage}</b>. {labels['lbl_PleaseRetry'] || 'Please retry'}.
        </div>
      </div>
    )
  }


  if (type === constants.stepTypes.RESULTS) {
    return  <div className={cn(styles.root, styles.results)}><Layout_Resource {...{ data }} /></div>
  }
}

export default Com
