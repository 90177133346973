@use "source/webComponent/theme" as *;

.root {
  width: 100%;
  background-color: $color_gray;
  border-radius: 5px;
  box-sizing: border-box;

  &.loading {
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.error {
    padding: 16px;
    color: $color_warning;
    b {
      font-weight: 900;
    }
  }

  &.results {
    // redundant to satisfy webComponent
    padding: 0px;
  }
}

