import React, { useContext, createContext, useState } from 'react'
import _ from 'lodash'
import cn from 'classnames'
import { OperaContext } from '@/lib/operaFrame/OperaProvider'
import InputZone_OPTIONS from '@/molecule/InputZone_OPTIONS'
import InputZone_LOC_ZIP from '@/molecule/InputZone_LOC_ZIP'

import * as styles from './styles.module.scss'
import constants from '@/core/utils/constants'

const Components = {
  [constants.stepTypes.LOC_ZIP]: InputZone_LOC_ZIP,
  [constants.stepTypes.OPTIONS]: InputZone_OPTIONS,
  [constants.stepTypes.OPTIONS_ONLY]: InputZone_OPTIONS
}

export default ({ className, ...rest }) => {
  const {
    tree,
    stage,
    waitingQueue,
    show
    //
  } = useContext(OperaContext)

  const currentType = tree[stage?.currentStep]?.type

  // load different inputs
  const Com = Components[currentType] || InputZone_OPTIONS
  const currentActor = _.get(stage, 'behavior.currentActor', '')
  const isInputDisplay = waitingQueue?.[0]?.character === constants.characters.USER

  if (!isInputDisplay) {
    return (
      <div className={cn(className, styles.loading_dots_container, styles.root)}>
        {/* loading */}
        <div className={cn(styles.loading_dots)}>
          <div className={cn(styles.dot)} />
          <div className={cn(styles.dot)} />
          <div className={cn(styles.dot)} />
        </div>
      </div>
    )
  }

  return (
    <div className={cn(className, styles.root, styles[show ? 'show': 'hide'])}>
      <Com />
    </div>
  )
}
