@use "source/webComponent/theme" as *;
.root {
  background-color: $color_gray;
  padding: $spacing_message_gap;
  .question {
    color: $color_primary;
  }
  .answer {

  }
}