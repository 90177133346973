export const LINKTYPES = {
  EXTERNAL: "EXTERNAL",
  ABSOLUTE: "ABSOLUTE",
  RELATIVE: "RELATIVE",
  PDF: "EXTERNAL",
  MAIL: "EXTERNAL",
  PHONE: "EXTERNAL",
};

export const TIME_BREAK_BETWEEN_MESSAGE = 600;


export default {
  // ====== for UI level ======
  characters: {
    USER: 'USER',
    BOT: 'BOT',
    SYSTEM: 'SYSTEM',
    STAGEMANAGER: 'STAGEMANAGER'
  },
  stepForSystem: {
    RESULTS: 'RESULTS',
    ENTRY: 'ENTRY'
  },
  scodeMethods: {
    ATTACH: 'ATTACH',
    REPLACE: 'REPLACE',
    // -- default
    default: 'ATTACH'
  },
  stepTypes: {
    OPTIONS: 'OPTIONS',
    SKIP: 'SKIP',
    HTMLPANEL: 'HTMLPANEL',
    LOC_ZIP: 'LOC_ZIP',
    OPTIONS_ONLY: 'OPTIONS_ONLY',
    RESULTS: 'RESULTS',
    // -- default
    default: 'OPTIONS'
  },
  operations: {
    AND: 'AND',
    OR: 'OR',
    NOTEXISTS: 'NOTEXISTS',
    // -- default
    default: 'AND'
  },
  messageTypes: {
    BUBBLE: 'BUBBLE',
    HTMLPANEL: 'HTMLPANEL',
    // -- default
    default: 'BUBBLE'
  },
  TIME_BREAK_BETWEEN_MESSAGE,

  // ====== for data level ======
  drawers: {
    'facility': 'facility',
    'settings': 'settings',
  },
  ACTIVITY_TYPES: {
    'FAC_DETAIL': 'FAC_DETAIL',
    'FAC_EXT_WEBSITE': 'FAC_EXT_WEBSITE',
    'FAC_EXT_HOTLINE': 'FAC_EXT_HOTLINE',
    'FAC_EXT_WARMLINE': 'FAC_EXT_WARMLINE',
    'FAC_EXT_PHONE': 'FAC_EXT_PHONE',
    'FAC_EXT_SMS': 'FAC_EXT_SMS',
  },
}