import React, { useContext, createContext, useState, useEffect } from 'react'
import _ from 'lodash'
import cn from 'classnames'

import IconCircleCheck from '@/assets/icons/IconCircleCheck'
import IconLanguage from '@/assets/icons/IconLanguage'

import { GeneralContext } from '@/provider/GeneralProvider'
import useFile from '@/core/hooks/useFile'
import utils from '@/core/utils/helper'

import Api from '@/provider/Api'
import * as styles from './styles.module.scss'

export default ({ className, ...rest }) => {
  const [tab, setTab] = useState('language')
  const {
    languageList,
    language,
    countryList,
    country
    //
  } = useContext(GeneralContext)

  const languageObj = languageList.find(item => item.language_code === language)
  const countryObj = countryList.find(item => item.country_code === country)

  return (
    <div className={cn(styles.root, className)}>
      <div className={cn(styles.header)}>
        <button
          onClick={() => setTab('language')}
          className={cn(styles.headerButton, tab === 'language' && styles.active)}
        >
          <IconLanguage className={cn(styles.languageIcon)} />
          <span>{languageObj?.native_name || 'Language'}</span>
        </button>
        <button
          onClick={() => setTab('country')}
          className={cn(styles.headerButton, tab === 'country' && styles.active)}
        >
          <img className={cn(styles.flag)} src={`${Api.CND_SERVER}/images/countries/${country?.toLowerCase()}.svg`} />
          <span>{countryObj?.country_name || 'Country'}</span>
        </button>
      </div>

      {/* keep both for smooth transition */}
      <ListLanguages tab={tab} />
      <ListCountries tab={tab} />
    </div>
  )
}

const ListLanguages = ({ tab }) => {
  const {
    onSwitchLanguage,
    languageList,
    language
    //
  } = useContext(GeneralContext)

  return (
    <div className={cn(styles.body, tab === 'language' ? styles.tabActive : styles.tabInactive)}>
      <div className={cn(styles.listContainer)}>
        {languageList.map((item, index) => {
          const { language_code, native_name } = item
          const isActive = language === language_code
          return (
            <div
              key={index}
              className={cn(styles.item, isActive && styles.active)}
              onClick={() => onSwitchLanguage(language_code)}
            >
              <span className={cn(styles.itemText)}>{native_name}</span>
              <span className={cn(styles.itemIcon)}>
                <IconCircleCheck />
              </span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const ListCountries = ({ tab }) => {
  const {
    onSwitchCountry,
    countryList,
    country
    //
  } = useContext(GeneralContext)

  return (
    <div className={cn(styles.body, tab === 'country' ? styles.tabActive : styles.tabInactive)}>
      <div className={cn(styles.listContainer)}>
        {countryList.map((item, index) => {
          const { country_code, country_name } = item
          const isActive = country === country_code
          return (
            <div
              key={index}
              className={cn(styles.item, isActive && styles.active)}
              onClick={() => onSwitchCountry(country_code)}
            >
              <span className={cn(styles.itemText)}>
                <img
                  className={cn(styles.flag)}
                  src={`${Api.CND_SERVER}/images/countries/${country_code?.toLowerCase()}.svg`}
                />
                <span>{country_name}</span>
              </span>
              <span className={cn(styles.itemIcon)}>
                <IconCircleCheck />
              </span>
            </div>
          )
        })}
      </div>
    </div>
  )
}
