@use "source/webComponent/theme" as *;


.show {
  display: flex;;
}
.hide {
  display: none;
}

.root {
  flex-direction: column;
}

.loading_dots_container {
  background-color: $color_gray;
  padding: $spacing_message_gap;
  .loading_dots {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loading_dots .dot {
    width: 5px;
    height: 5px;
    margin: 0 5px;
    border-radius: 50%;
    background-color: $color_primary_hover; /* Change color as needed */
    animation: dot-flash 1s infinite;
  }

  .loading_dots .dot:nth-child(1) {
    animation-delay: 0s;
  }

  .loading_dots .dot:nth-child(2) {
    animation-delay: 0.2s;
  }

  .loading_dots .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
}

@keyframes dot-flash {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
