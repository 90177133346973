import React, { useContext, useEffect, createContext, useState } from 'react'
import _ from 'lodash'
import constants from '@/core/utils/constants'
import { OperaContext } from '../OperaProvider'

/**
 * directs the conversation flow. focus on who should speak next
 * wont do stage changes, only assign tasks (to prevent potential infinite loop)
 * @param {*} props
 */
const useDirector = props => {
  const {
    tree,
    stage,
    setStage,

    waitingQueue,
    onSchedulePerformanceList,
    onError
    //
  } = useContext(OperaContext)

  useEffect(() => {
    // if there is no waiting queue, director assign tasks
    if (stage && tree && _.isEmpty(waitingQueue)) {
      process(stage, tree)
    }
  }, [stage, tree, waitingQueue])

  const process = (stage, tree) => {
    /* 
      when queue is empty, director will assign tasks
      check if there is a next step, if not, means
      1. either logicTree configuration is wrong. (need to fix from the CMS)
      2. role is not properly setup the next step. (need to fix from front-end)
      throw exception
    */
    const { currentStep, nextStep } = stage
    const nextStepObj = tree[nextStep]
    if (!nextStepObj) {
      console.error('No next step. Conversation ended. nextStep code is:', nextStep)
      onError('No next step. Conversation ended.')
      return
    }

    // according to the next step type, assign to different actor. Some steps needs multiple actors
    const nextType = nextStepObj.type || constants.stepTypes.default // default "OPTIONS"
    console.log("director assign", nextType, nextStepObj)

    /* 
      director enqueue (actor will run similar "switch-case" but: 1.limited to their character, 2. more detailed action)
      primary purpose of director is: to split codes into different hooks (I call it assign tasks)
      
      for roles:
      - "system"/"bot": will aggressively pick tasks, 
      - "user" task will be:
        render the input whenever conditions are met (e.g. current step has "options" field, or type is "loc_zip")
        awaiting user input, once done, task will auto dequeue from waitingQueue. Its to satisfy the process
      - "stagemanager" will be the exception handler, it doesnt pick task. It will showup when there is an exception
    */
    switch (nextType) {
      case constants.stepTypes.SKIP:
      case constants.stepTypes.CLEAR:
        onSchedulePerformanceList(
          {
            step: nextStep,
            character: constants.characters.SYSTEM // let system process variables
          }
        )
        break
      case constants.stepTypes.HTMLPANEL:
        // bot only
        onSchedulePerformanceList(
          {
            step: nextStep,
            character: constants.characters.BOT
          }
        )
        break
      case constants.stepTypes.RESULTS:
        // bot sends a message(only if there is), then system will process the results
        onSchedulePerformanceList(
          {
            step: nextStep,
            character: constants.characters.BOT
          },
          {
            step: nextStep,
            character: constants.characters.SYSTEM
          }
        )
        break
      case constants.stepTypes.OPTIONS:
      case constants.stepTypes.OPTIONS_ONLY:
      case constants.stepTypes.LOC_ZIP:
        // bot sends a message, then awaiting for user input
        onSchedulePerformanceList(
          {
            step: nextStep,
            character: constants.characters.BOT // let system process variables
          },
          {
            step: nextStep,
            character: constants.characters.USER // let system process variables
          }
        )
        break
      default:
        break
    }

    return
  }
}

export default useDirector
