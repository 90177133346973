@use "source/webComponent/theme" as *;

$logo_height: 115px;

.hidden{
  // to calculate pixel width of font
  position: absolute;
  visibility: hidden;
  white-space: nowrap;
  font-size: 1em;
}

.error {
  color: $color_warning;
  b {
    font-weight: 900;
  }
}

.overlayBadge {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  background-color: $color_primary;
  text-align: center;
  padding: 4px;
  box-sizing: border-box;
  font-weight: 900;
  gap: 5px;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 0 10px;
  align-items: center;
  z-index: 3;
  border-left: 1px solid $color_border;
  border-bottom: 1px solid $color_border;
  .icon {
    width: 15px;
    height: 15px;
  }
}

.phonesCard {
  .overlayBadge {
    background-color: #f44336;
  }
  .logo_container {
    position: relative;
    .logo_image {
      position: absolute;
      top: 0px;
    }
  }
  .phonesTitle {
    margin-top: $spacine_px; // space for badge
    font-size: 1.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  .overlayTitle {
    padding: $spacine_px_sm;
    color: black;
    position: absolute;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 0.8em;
    position: relative;
    min-height: $logo_height;
    // background-image: linear-gradient(to bottom, #ffd95f, #ffd95f30);
  }
  .tapButton {
    margin-top: $spacine_px;
  }
}

.smsCard {
  .overlayBadge {
    background-color: #4caf50;
  }
  .logo_container {
    position: relative;
    .logo_image {
      position: absolute;
      top: 0px;
    }
  }
  .smsTitle {
    margin-top: $spacine_px; // space for badge
    font-size: 1.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  .overlayTitle {
    padding: $spacine_px_sm;
    color: black;
    position: absolute;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 0.8em;
    position: relative;
    min-height: $logo_height;
    // background-image: linear-gradient(to bottom, #bbebbc, #bbebbc30);
  }
  .tapButton {
    margin-top: $spacine_px;
  }
}

.defaultCard {
  .overlayBadge {
    background-color: $color_primary;
  }
  .overlayTitle {
    // border-bottom: 1px solid $color_border;
    color: $color_mute;
    font-weight: normal;
    padding-bottom: 12px;
    justify-content: flex-start;
    background-color: transparent !important;
    font-size: $fontBody - 2;
    // background-image: linear-gradient(to bottom, $color_primary, transparent);
  }
  .cardContentContainer {
    &:hover {
      .overlayTitle {
        color: $color_link;
      }
    }
  }
}

.items {
  @include flexRow($alignY: stretch);
  justify-content: center;
  padding: 32px 16px;
  width: 100%;
  gap: 20px;

  font-family: monospace;

  @include breakpoint-up(md) {
    // justify-content: flex-start;
  }

  .card {
    @include flexColumn();
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    align-items: stretch;
    background-color: $warmWhite;
    border-radius: $spacing_borderradius_sm;
    overflow: hidden;
    .cardContentContainer {
      @include flexColumn();
      border-bottom: 1px solid $color_border;
      color: $color_link;
      text-decoration: none;
      .overlayTitle {
        top: 0px;
        left: 0px;
        width: 100%;
        text-align: center;
        box-sizing: border-box;
        gap: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        // -webkit-text-stroke-width: 1px; /* Stroke width */
        // -webkit-text-stroke-color: white; /* Stroke color */
        // position: absolute;
        // text-shadow: -2px -2px #fff, 2px -2px #fff, -2px 2px #fff, 2px 2px #fff;
        //  height: 100%;
      }
      cursor: pointer;
      padding: $spacine_px;
      width: 100%;

      &:hover {
        .overlayTitle {
          text-decoration: underline;
        }
        .logo_image {
          // opacity: .8;
          transition: all 0.2s;
        }
      }
    }

    svg {
      height: 56px;
      margin: 16px 0;
      width: auto;
    }

    .detailContainer {
      @include flexColumn();
      align-items: stretch;
      cursor: pointer;
      text-align: center;
      &:hover {
        .title {
          color: $color_link;
        }
        .detail {
          color: $color_link;
          text-decoration: underline;
        }
      }

      .title {
        // color: #002677;
        font-size: $fontBody;
        padding: $spacine_px;
        padding-bottom: 0;
        font-weight: 600;
        line-height: 18px;
      }

      .detail {
        padding: $spacine_px;
        color: $color_mute;
        font-size: $fontBody - 2;

        // color: $color_link;
        // background-color: $color_background;
      }
    }

    p {
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .top {
      @include flexRow($alignX: space-between, $wrap: nowrap);
      width: 100%;
      font-size: 12px;
      text-transform: uppercase;
      opacity: 0.5;
    }

    .icons svg {
      cursor: pointer;
      height: auto;
      fill: #002677;
      margin: 0;
      width: 24px;
      &:hover {
        filter: brightness(2);
      }
    }

    .logo_container {
      width: 100%;
      position: relative;
      .logo_image {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 115px;
        min-width: 120px;
        width: 100%;
        transition: all 0.2s;
      }
    }

    .arrow svg {
      margin-inline-start: 4px;
      width: 16px;
    }
  }

  .noResult {
    color: $color_mute;
  }
}

.footer {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  .showMoreButton {
    background-color: $color_gray;
    border: 1px solid $color_border;
    border-radius: 20px;
    color: $color_primary;
    font-weight: bold;
    cursor: pointer;
    font-size: $fontBody;
    padding: $spacine_px $spacine_px * 2;
    text-align: center;
    &:hover {
      background-color: $warmWhite;
      // color: $color_buttonHover;
    }
  }
}