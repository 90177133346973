@use 'source/webComponent/theme' as *;

.root {
  background-color: $color_background;
  display: flex;
  flex-direction: row;
  
  .inputBoxContainer {
    flex-grow: 1;
    padding: $spacine_px;
    input {
      font-size: $fontBody2;
      box-sizing: border-box;
      width: 100%;
      padding: 8px 12px;
      border: none;
      // border-bottom: 1px solid $color_border;
      // box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
      &:focus {
        outline: none;
      }
    }
  }
  .inputApplyButton {
    padding: $spacine_px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border: none;
    cursor: pointer;
    width: 40px;
    background: transparent;
    &:hover {
      opacity: 0.7;
    }
    svg {
      height: 20;
      width: 20;
      fill: $color_textLight;
    }
  }
}
