import React, { useEffect, useContext, useState, useRef } from 'react'
import cn from 'classnames'
import DefaultLogo from '@/assets/images/default_logo.png'
import DefaultLogo_PhoneHotline from '@/assets/images/hotline_thumbnail.webp'
import DefaultLogo_PhoneWarmline from '@/assets/images/warmline_thumbnail.webp'
import DefaultLogo_SmsHotline from '@/assets/images/sms_hotline_thumbnail.webp'
import DefaultLogo_SmsWarmline from '@/assets/images/sms_warmline_thumbnail.webp'

import iconPhone from '@/assets/icons/phone-solid-white.svg'
import iconGlobal from '@/assets/icons/global-white.svg'
import iconMessage from '@/assets/icons/message-bubble-white.svg'
import useEngagement from '@/core/hooks/useEngagement'
import constants from '@/core/utils/constants'

import Api from '@/provider/Api'
import utils from '@/core/utils/helper'
import { GeneralContext } from '@/provider/GeneralProvider'
import * as styles from './styles.module.scss'
import useFile from '@/core/hooks/useFile'

const PER_PAGE = 4
const CARD_WIDTH = 190
const TEXT_CONTAINER_WIDTH = CARD_WIDTH - 60

const Com = ({ data }) => {
  const {
    setShowFacility,
    setUiDrawer,
    labels,
    //
  } = useContext(GeneralContext)

  const results = data
  const [error, setError] = useState()
  const [page, setPage] = useState(0)

  const handleOnClick = fac => {
    setShowFacility(fac)
    setUiDrawer(constants.drawers.facility)
  }
 
  const handleShowMore = () => {
    if (page * PER_PAGE >= results.length) return
    setPage(page + 1)
  }

  // Return null since we don't want to render anything for this step
  return (
    <div className={cn(styles.items)}>
      {results.length > 0 ? (
        <>
          {results.slice(0, (page + 1) * PER_PAGE).map((item, i) => {
            const { website, card_type, phone_hotline, sms, public_phone } = item

            let CardWithType

            switch (card_type) {
              case 'sms':
                CardWithType = sms ? CardSMS : null
                break
              case 'hotline':
                // logic here: if there is no hotline, sms will be the priority
                if (phone_hotline) {
                  CardWithType = CardHotline
                } else if (sms) {
                  CardWithType = CardSMS
                }
                break
              case 'warmline':
                if (phone_hotline) {
                  CardWithType = CardWarmline
                } else if (sms) {
                  CardWithType = CardSMS
                }
                break
              case 'website':
                CardWithType = website ? CardWebsite : null
                break
              case 'phone':
                CardWithType = CardPublicPhone
                break
              case 'facility':
                CardWithType = CardWebsite
              default:
                // if its null. priority is website -> phone -> sms
                if (website) {
                  CardWithType = CardWebsite
                } else if (phone_hotline) {
                  CardWithType = CardHotline
                } else if (public_phone) {
                  CardWithType = CardPublicPhone
                } else if (sms) {
                  CardWithType = CardSMS
                }
            }

            // exception catch
            // if there is no website, phone_hotline, sms, no matter what types its publich phone
            if (!CardWithType) CardWithType = CardPublicPhone
            return (
              <React.Fragment key={item.org_id}>
                <CardWithType item={item} labels={labels} onClick={handleOnClick} />
              </React.Fragment>
            )
          })}
          {// if there are more results to show
          (page + 1) * PER_PAGE < results.length ? (
            <div className={cn(styles.footer)} style={{ userSelect: 'none' }} onClick={handleShowMore}>
              <div className={cn(styles.showMoreButton)}>
                {labels['lbl_Remaining'] || 'Remaining...'} ({Math.min((page + 1) * PER_PAGE, results.length)} /{' '}
                {results.length})
              </div>
            </div>
          ) : null}
        </>
      ) : (
        <div className={cn(styles.noResult)}>{labels['lbl_NoResults'] || 'No results'}</div>
      )}
    </div>
  )
}

const CardWebsite = ({ labels, item, onClick }) => {
  const { org_id, website, name1, name2, logo, description, card_type } = item
  const title = [name1 || '', name2 || ''].join(' ')
  const { getImage } = useFile()

  const handleRecord = useEngagement(null)(constants.ACTIVITY_TYPES.FAC_EXT_WEBSITE, org_id, website)
  const handleRecordDetail = useEngagement(item => {
    onClick(item)
  })(constants.ACTIVITY_TYPES.FAC_DETAIL, org_id)

  return (
    <div key={org_id} className={cn(styles.card, styles.defaultCard)} style={{ width: CARD_WIDTH }}>
      <div className={cn(styles.overlayBadge)}>
        <img src={iconGlobal} alt="website" className={cn(styles.icon)} />
      </div>
      {/* <Icon icon={icon} /> */}
      {website ? (
        <a onClick={handleRecord} className={cn(styles.cardContentContainer)} href={website} target="_blank">
          <div className={cn(styles.logo_container)}>
            <div className={cn(styles.overlayTitle)}>{labels['lbl_VisitWebsite'] || 'Visit Website'}</div>
            <div
              className={cn(styles.logo_image)}
              style={{
                background: `url(${getImage(logo) || DefaultLogo}) no-repeat center center`
              }}
            />
          </div>
        </a>
      ) : null}
      <div
        className={cn(styles.detailContainer)}
        onClick={() => {
          handleRecordDetail(item)
        }}
      >
        <div className={cn(styles.title)}>{title}</div>
        <div className={cn(styles.detail)}>{labels['lbl_Details'] || 'Details'}</div>
      </div>
    </div>
  )
}

const CardSMS = ({ labels, item, onClick }) => {
  const { org_id, sms, sms_msg, name1, name2, logo, sms_details, card_type } = item
  const title = [name1 || '', name2 || ''].join(' ')
  const { getImage } = useFile()

  const _bg = card_type === 'hotline'? DefaultLogo_SmsHotline : DefaultLogo_SmsWarmline
  const link = `sms:${utils.extractPhone(sms)}?body=${sms_msg}`

  const handleRecord = useEngagement(null)(constants.ACTIVITY_TYPES.FAC_EXT_SMS, org_id, link)
  const handleRecordDetail = useEngagement(item => {
    onClick(item)
  })(constants.ACTIVITY_TYPES.FAC_DETAIL, org_id)

  const [fontSize, setFontSize] = useState('1em')
  const hiddenRef = useRef(null)
  useEffect(() => {
    const textWidth = hiddenRef.current.offsetWidth
    // calculage the desired font size by textWidth and container width (CARD_WIDTH)
    const _fontSize = Math.min(2.5, TEXT_CONTAINER_WIDTH / textWidth) + 'em'
    setFontSize(_fontSize)
  }, [sms])

  return (
    <div key={org_id} className={cn(styles.card, styles.smsCard)} style={{ width: CARD_WIDTH }}>
      <div className={cn(styles.overlayBadge)}>
        <img src={iconMessage} alt="sms" className={cn(styles.icon)} />
      </div>
      {/* <Icon icon={icon} /> */}
      <a onClick={handleRecord} className={cn(styles.cardContentContainer)} href={link} target="_blank">
        <div className={cn(styles.logo_container)}>
          <div className={cn(styles.overlayTitle)}>
            <span className={cn(styles.hidden)} ref={hiddenRef}>
              {sms}
            </span>
            <div className={cn(styles.smsTitle)} style={{ fontSize }}>
              {sms}
            </div>
            {sms_details ? <div>{sms_details}</div> : null}
            <div className={cn(styles.tapButton)}>{labels['lbl_TapToMessageNow'] || 'Tap to message now'}</div>
          </div>
          <div
            className={cn(styles.logo_image)}
            style={{
              background: `url(${_bg}) no-repeat center center`
            }}
          />
        </div>
      </a>
      <div
        className={cn(styles.detailContainer)}
        onClick={() => {
          handleRecordDetail(item)
        }}
      >
        <div className={cn(styles.title)}>{title}</div>
        <div className={cn(styles.detail)}>{labels['lbl_Details'] || 'Details'}</div>
      </div>
    </div>
  )
}

const CardHotline = ({ labels, item, onClick }) => {
  const { org_id, phone_hotline } = item

  const link = `tel:${utils.extractPhone(phone_hotline)}`

  const handleRecord = useEngagement(null)(constants.ACTIVITY_TYPES.FAC_EXT_HOTLINE, org_id, link)
  const handleRecordDetail = useEngagement(item => {
    onClick(item)
  })(constants.ACTIVITY_TYPES.FAC_DETAIL, org_id)

  return (
    <COM_CardPhones
      {...{
        labels,
        item,
        handleRecord,
        handleRecordDetail,
        _link: link,
        _phone: phone_hotline,
        _bg: DefaultLogo_PhoneHotline
      }}
    />
  )
}

const CardWarmline = ({ labels, item, onClick }) => {
  const { org_id, phone_hotline } = item

  const link = `tel:${utils.extractPhone(phone_hotline)}`

  const handleRecord = useEngagement(null)(constants.ACTIVITY_TYPES.FAC_EXT_WARMLINE, org_id, link)
  const handleRecordDetail = useEngagement(item => {
    onClick(item)
  })(constants.ACTIVITY_TYPES.FAC_DETAIL, org_id)

  return (
    <COM_CardPhones
      {...{
        labels,
        item,
        handleRecord,
        handleRecordDetail,
        _link: link,
        _phone: phone_hotline,
        _bg: DefaultLogo_PhoneWarmline
      }}
    />
  )
}

const CardPublicPhone = ({ item, onClick, ...rest }) => {
  // NOTE: currently copied from hotline, use warmline background
  const { org_id, public_phone } = item
  const link = `tel:${utils.extractPhone(public_phone)}`
  const handleRecord = useEngagement(null)(constants.ACTIVITY_TYPES.FAC_EXT_PHONE, org_id, link)
  const handleRecordDetail = useEngagement(item => {
    onClick(item)
  })(constants.ACTIVITY_TYPES.FAC_DETAIL, org_id)

  return (
    <COM_CardPhones
      {...{
        ...rest,
        item,
        handleRecord,
        handleRecordDetail,
        _link: link,
        _phone: public_phone,
        _bg: DefaultLogo_PhoneWarmline
      }}
    />
  )
}

const COM_CardPhones = ({ labels, item, _link, _phone, _bg, handleRecordDetail, handleRecord }) => {
  const { org_id, name1, name2, phone_details } = item
  const title = [name1 || '', name2 || ''].join(' ')

  const [fontSize, setFontSize] = useState('1em')
  const hiddenRef = useRef(null)
  useEffect(() => {
    const textWidth = hiddenRef.current.offsetWidth
    // calculage the desired font size by textWidth and container width (CARD_WIDTH)
    const _fontSize = Math.min(2.5, TEXT_CONTAINER_WIDTH / textWidth) + 'em'
    setFontSize(_fontSize)
  }, [_phone])

  return (
    <div key={org_id} className={cn(styles.card, styles.phonesCard)} style={{ width: CARD_WIDTH }}>
      <div className={cn(styles.overlayBadge)}>
        <img src={iconPhone} alt="phone" className={cn(styles.icon)} />
      </div>
      {/* <Icon icon={icon} /> */}
      <a onClick={handleRecord} className={cn(styles.cardContentContainer)} href={_link} target="_blank">
        <div className={cn(styles.logo_container)}>
          <div className={cn(styles.overlayTitle)}>
            <span className={cn(styles.hidden)} ref={hiddenRef}>
              {_phone}
            </span>
            <div className={cn(styles.phonesTitle)} style={{ fontSize }}>
              {_phone}
            </div>
            {phone_details ? <div>{phone_details}</div> : null}
            <div className={cn(styles.tapButton)}>{labels['lbl_TapToCallNow'] || 'Tap to call now'}</div>
          </div>
          <div
            className={cn(styles.logo_image)}
            style={{
              background: `url(${_bg}) no-repeat center center`
            }}
          />
        </div>
      </a>
      <div
        className={cn(styles.detailContainer)}
        onClick={() => {
          handleRecordDetail(item)
        }}
      >
        <div className={cn(styles.title)}>{title}</div>
        <div className={cn(styles.detail)}>{labels['lbl_Details'] || 'Details'}</div>
      </div>
    </div>
  )
}

export default Com
