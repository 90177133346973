import React, { createContext, useState, useEffect } from 'react'
import _, { set } from 'lodash'
import Api from './Api'
import { OperaProvider } from '@/lib/operaFrame/OperaProvider'
import constants from '@/core/utils/constants'
import helper from '@/core/utils/helper'

export const GeneralContext = createContext(null)

// we leave it empty so that server can detect from IP. real default county set from server because for country we need IP detection
const DEFAULT_COUNTRY = ''
const DEFAULT_LANGUAGE = 'en-US'

const cookieLang = localStorage.getItem('rivara_currentLanguage') || ''
const cookieCountry = localStorage.getItem('rivara_currentCountry') || ''

/* 
  NOTE: react web component doesnt allow "undefined" as useEffect dependency 
  so we need to set default value for all props
  otherwise, it will crush without any error message
*/
export const GeneralProvider = ({
  className,
  initAccessCode = '',
  initLanguage = '',
  initCountry = '',
  initUserAvatar = '',
  initZIndex = '',
  initCookieOff = false,
  initIsPreview = false,
  children,
  // ==== custom updates from outside ====
  customLanguage = '',
  customCountry = '',
  customAccessCode = '',
  customUserAvatar = '',
  customCookieOff = false,
  triggerer = '',
  show,
  setShow
}) => {
  // passed in
  const [language, setLanguage] = useState(initLanguage || cookieLang || DEFAULT_LANGUAGE)
  const [country, setCountry] = useState(initCountry || cookieCountry || DEFAULT_COUNTRY)
  const [accessCode, setAccessCode] = useState(initAccessCode)
  const [userAvatar, setUserAvatar] = useState(initUserAvatar)
  const [zIndex, setZIndex] = useState(initZIndex)
  const [cookieOff, setCookieOff] = useState(initCookieOff)
  const isPreview = initIsPreview === 'true' || initIsPreview === true || initIsPreview === '1' || initIsPreview === 1

  // for first load. then we clamp it to the context
  const [labels, setLabels] = useState({})
  const [loaded, setLoaded] = useState(false)
  const [globalErrorMessage, setGlobalErrorMessage] = useState(null)

  const [tree, setTree] = useState(null)

  const [token, setToken] = useState(null)
  const [client, setClient] = useState(null)

  const [languageList, setLanguageList] = useState([])
  const [countryList, setCountryList] = useState([])
  const [visitorId, setVisitorId] = useState('')

  const [direction, setDirection] = useState('ltr')
  
  // {org_id: 20369}
  const [showFacility, setShowFacility] = useState(null)

  // UI
  const [uiDrawer, setUiDrawer] = useState('')

  // only used once. to record visitor when first open the chatbox
  const [hasRecordedVisitor, setHasRecordedVisitor] = useState(false)

  const zIndexInt = parseInt(zIndex) || 1000000

  useEffect(() => {
    if (accessCode) {
      // validate access code
      initClient(accessCode)
    }
  }, [accessCode])

  useEffect(() => {
    if (token && language && country) {
      // read tree and translations
      initRivara(token, language, country)
    }
  }, [token, language, country])

  useEffect(() => {
    if (token && show && !hasRecordedVisitor) {
      // only used once. to record visitor when first open the chatbox
      initVisitor(token)
    }
  }, [token, show, hasRecordedVisitor])

  // ============= custom updates from outside =============
  useEffect(() => {
    if (customAccessCode || customLanguage) {
      setAccessCode(customAccessCode)
      setLanguage(customLanguage)
      setCountry(customCountry)
    }
  }, [customAccessCode, customLanguage, customCountry, triggerer])

  useEffect(() => {
    if (customUserAvatar) {
      setUserAvatar(customUserAvatar)
    }
  }, [customUserAvatar, triggerer])

  useEffect(() => {
    if (customCookieOff) {
      setCookieOff(customCookieOff)
    }
  }, [customCookieOff, triggerer])
  // ============= custom updates from outside =============

  const onSwitchLanguage = async lang => {
    setLanguage(lang)
    setUiDrawer('')
  }

  const onSwitchCountry = async country => {
    setCountry(country)
    setUiDrawer('')
  }

  const onException = (chatMessage, messageType = 'error') => {
    setGlobalErrorMessage({ chatMessage, messageType })
  }

  async function initClient(accessCode) {
    setGlobalErrorMessage(null)
    setLoaded(false)
    try {
      // init client by token
      const res = await Api.getToken(accessCode)
      if (res?.invalid) {
        throw new Error(res?.messages)
      }

      setToken(res.token)
      setClient(res.client)

      // init settings: language list, country list, detected country if empty
      const { languageList, countryList, ipCountry } = await Api.getSettings(res.token, cookieLang, cookieCountry, {
        isPreview
      })
      setLanguageList(languageList || [])
      setCountryList(countryList || [])
      if (ipCountry) {
        setCountry(ipCountry)
      }
    } catch (err) {
      onException(err?.message)
    } finally {
      setLoaded(true)
    }
  }

  async function initRivara(token, lang, country) {
    setGlobalErrorMessage(null)
    setLoaded(false)
    try {
      // get the tree
      const data = await Api.getTree(token, lang, country, { cookieOff, isPreview })
      if (!data?.logicTree) {
        throw new Error(
          labels['lbl_Error_LostConnection'] || 'Oops, we lost connection to the server. Please try again later.'
        )
      }

      setTree(data?.logicTree)
      setLabels(data?.labels)
      setDirection(data?.direction || 'ltr')
    } catch (err) {
      onException(err?.message)
    } finally {
      setLoaded(true)
    }
  }

  async function initVisitor(token) {
    try {
      // get visitor_token
      let visitor_token = localStorage.getItem('visitor_token') || ''

      // if we dont turn off cookie, and we dont have token. means new visitor
      if (!cookieOff && !visitor_token) {
        visitor_token = 'new'
      }

      const visitor = await Api.recordVisitor({ visitor_token, accesstoken: token, isPreview })

      // store visitor
      if (visitor?.visitor_token && !cookieOff) {
        localStorage.setItem('visitor_token', visitor?.visitor_token)
      }

      if (visitor?.visitor_id) {
        setVisitorId(visitor?.visitor_id)
      }

      setHasRecordedVisitor(true)
    } catch (err) {
      console.error('recordVisitor', err)
    } finally {
    }
  }

  const onProcessPayload = helper.processPayload

  const onFetchResults = async payload => {
    const res = await Api.getResults(token, { ...payload, lang: language, country }, { isPreview })
    return res
  }

  const contextData = {
    // init data
    tree,
    globalErrorMessage,
    setGlobalErrorMessage,
    labels,
    languageList,
    countryList,
    language,
    country,
    userAvatar,
    showFacility,
    setShowFacility,
    direction,
    uiDrawer,
    setUiDrawer,
    show,
    setShow,
    isPreview,
    onSwitchLanguage,
    onSwitchCountry,
    onProcessPayload,
    onException,
    onFetchResults,
    zIndex: zIndexInt,
    client,
    token,
    visitorId,
    loaded
  }

  if (!accessCode) {
    return <div style={{ display: 'none' }}>Rivara: no access code</div>
  }
  return (
    <GeneralContext.Provider value={{ ...contextData }}>
      <OperaProvider {...contextData}>{children}</OperaProvider>
    </GeneralContext.Provider>
  )
}
