import React, { useContext, createContext, useState } from 'react'
import cn from 'classnames'
import { OperaContext } from '@/lib/operaFrame/OperaProvider'
import IconSend from '@/assets/icons/IconSend'

import * as styles from './styles.module.scss'

export default ({ className, placeholder, value, onChange, onApply, ...rest }) => {
  const {
    //
  } = useContext(OperaContext)

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      onApply(e)
    }
  }

  return (
    <div className={cn(className, styles.root)}>
      <div className={cn(styles.inputBoxContainer)}>
        <input
          type="text"
          onKeyDown={handleKeyDown}
          {...{
            placeholder,
            onChange,
            value
          }}
        />
      </div>
      <button className={cn(styles.inputApplyButton)} onClick={onApply}>
        <IconSend />
      </button>
    </div>
  )
}
