import React, { createContext, useRef, useState, useEffect, useContext } from 'react'
import _ from 'lodash'
import Api from '../../provider/Api'
import constants from '@/core/utils/constants'

import useDirector from '@/lib/operaFrame/operaHooks/useDirector'
import useBot from '@/lib/operaFrame/operaHooks/useBot'
import useSystem from '@/lib/operaFrame/operaHooks/useSystem'
import useStageManager from '@/lib/operaFrame/operaHooks/useStageManager'

export const OperaContext = createContext(null)

const initStage = {
  nextStep: 'ENTRY'
}

export const OperaProvider = ({
  className,
  children,
  onProcessPayload = () => {
    return null
  },
  ...props
}) => {
  const { tree, labels } = props
  // for first load. then we clamp it to the context

  // to store halting parameters
  const [stage, setStage] = useState(initStage)

  // whenever its empty, director will step in to assign the next actor
  const [waitingQueue, setWaitingQueue] = useState([])

  // to store history speaklines
  const [dialogueHistory, setDialogueHistory] = useState([])

  const [errorMessage, setErrorMessage] = useState(null)

  const generateUniqueId = useUniqueId()

  useEffect(() => {
    clear()
  }, [tree])

  useEffect(() => {
    console.log('dialogueHistory', dialogueHistory)
  }, [dialogueHistory])

  const onSpeakLine = (character = 'SYSTEM', message, stepType, slId) => {
    // message can be string or object
    // { data, errorMessage, isMessageLoading , chatMessage }
    const speakLine = { message, type: stepType, step: stage?.currentStep, character }
    const _newSlId = generateUniqueId()
    // if slId is provided, then update the existing message
    if (slId) {
      setDialogueHistory(prev => {
        const _v = _.cloneDeep(prev)
        const _idx = _v.findIndex(item => item.slId === slId)
        if (_idx >= 0) {
          _v[_idx] = { slId, ...speakLine }
          return _v
        } else {
          return prev
        }
      })
    } else {
      speakLine.slId = _newSlId
      setDialogueHistory(prev => [...prev, speakLine])
      return _newSlId
    }
  }

  const onToastSpeakLine = (character = 'SYSTEM', message, stepType, options = {}) => {
    const {callback, delay} = options
    // speakLine then auto remove after 5 seconds
    const slId = onSpeakLine(character, message, stepType)
    setTimeout(() => {
      if (callback) {
        callback()
      }      
      onCancelSpeakLine(slId)
    }, delay || 3000)  
  }

  const onCancelSpeakLine = slId => {
    setDialogueHistory(prev => {
      const _v = _.cloneDeep(prev)
      const _idx = _v.findIndex(item => item.slId === slId)
      if (_idx >= 0) {
        _v.splice(_idx, 1)
      }
      return _v
    })
  }

  const onError = (chatMessage, messageType = 'error') => {
    setErrorMessage({ chatMessage, messageType })
  }

  // ============ start: queue flow ============
  /*
    its very important for the flow. It will have endless loop if not handled properly
    the onPerformance is the stopper of the loop. so every schedule should have onPerformance

    onClaimPerformance is to check if the actor is the one who can claim the performance
    both onClaimPerformance and onPerformance only work on the first object in the queue. (no "cut in the line")
    Reason we split to 2 functions: when its User role, after claim, we need it stop to wait for user input.
  */
  const onSchedulePerformanceList = (...performanceList) => {
    setWaitingQueue(prev => [...prev, ...performanceList])
  }

  const onClaimPerformance = (character = '') => {
    // if list is empty, return false
    if (_.isEmpty(waitingQueue)) {
      return false
    }

    /* 
      check if current actor is the one who can claim the performance 
      matches character, and not halted (prevent same actor claim twice due to async)
    */
    const _firstPfmc = waitingQueue[0]
    if (_firstPfmc?.character !== character || _firstPfmc?.isHalt) {
      return false
    }

    return _firstPfmc
  }

  const onPerformance = (isHalt = false) => {
    setWaitingQueue(prev => {
      const _v = _.cloneDeep(prev)
      if (_v?.length > 0) {
        if (isHalt) {
          // in case we want to wait for further action
          _v[0].isHalt = isHalt
        } else {
          _v.shift()
        }
      }
      return _v
    })
  }
  // ============ end: queue flow ============

  const clear = async () => {
    // primary trigger when first load or language/country change
    setStage(initStage)
    setDialogueHistory([])
    setWaitingQueue([])
  }

  const contextData = {
    ...props,
    // init data
    labels,

    // stage management
    stage,
    setStage,
    dialogueHistory,
    errorMessage, 
    setErrorMessage,

    // actions
    onSpeakLine,
    onCancelSpeakLine, // if error, "loading..." should be removed
    onToastSpeakLine,
    onProcessPayload,

    // for director assignment
    onSchedulePerformanceList,
    onClaimPerformance,
    onPerformance,
    onError,
    waitingQueue
  }

  // control process

  return (
    <div className={className} style={{ direction: tree?.direction }}>
      <OperaContext.Provider value={{ ...contextData }}>
        <HooksWrapper />
        {children}
      </OperaContext.Provider>
    </div>
  )
}

// a wrapper to include all hooks
const HooksWrapper = () => {
  useDirector()
  useBot()
  useSystem()
  useStageManager()

  return null
}

const useUniqueId = () => {
  // Initialize a ref to hold the counter value
  const counterRef = useRef(0)

  // Function to generate a unique ID
  const generateUniqueId = () => {
    // Increment the counter
    counterRef.current += 1
    // Combine the current slId with the counter value
    const uniqueId = `${Date.now()}-${counterRef.current}`
    return uniqueId
  }

  return generateUniqueId
}
