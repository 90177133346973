import React, { useContext, useRef, useEffect, createContext, useState } from 'react'
import _ from 'lodash'
import constants from '@/core/utils/constants'
import { OperaContext } from '../OperaProvider'

const CHARACTER = constants.characters.STAGEMANAGER

/**
 * passively listens for stage changes
 * @param {*} props
 */
const useBot = props => {
  const {
    errorMessage,
    setErrorMessage,
    onToastSpeakLine,
    onSpeakLine
    //
  } = useContext(OperaContext)

  useEffect(() => {
    if (errorMessage) {
      // onToastSpeakLine(CHARACTER, { ...errorMessage }, '', {
      //   callback: () => setErrorMessage(null),
      //   delay: 3000
      // })

      onSpeakLine(CHARACTER, { ...errorMessage })
      setErrorMessage(null)
    }
  }, [errorMessage])
}

export default useBot
