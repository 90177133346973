  // workaround (associate with our db)
  // referring to: https://www.metamodpro.com/browser-language-codes
  export const mappingLang = {
    'en-US': 'en',
  }

  // to handle all db 'aa-BB' format languages. otherwise will cut first 2 digits as language code
  export const mappingLangBrowser = {
    zh: 'zh-Hans',
    en: 'en-US',
    fr: 'fr-FR',
    'zh-cn': 'zh-Hans',
    'zh-sg': 'zh-Hans',
    'zh-hk': 'zh-Hant',
    'zh-tw': 'zh-Hant',
    'zh-mo': 'zh-Hant',
    'fr-ca': 'fr-CA',
  }

export default (lang, country) => {
  // map (lang+country from db) to browser standard
  // const langMapped = mappingLang[lang] || lang
  const langMapped = mappingLang[lang] || lang

  let mappingCountry
  let defaultCountry = ''

  if (langMapped === 'en') {
    defaultCountry = 'GB'
    mappingCountry = {
      AT: 'AT',
      CA: 'CA',
      GB: 'GB',
      US: 'US',
    }
  }

  if (langMapped === 'es') {
    mappingCountry = {
        ES: 'ES',
        MX: 'MX'
    }
  }

  if (langMapped === 'fr') {
    mappingCountry = {
      CA: 'CA',
      FR: 'FR'
    }
  }

  if (langMapped === 'pt') {
    mappingCountry = {
      BR: 'BR',
      PT: 'PT'
    }
  }

  const countryMapped = mappingCountry ? (mappingCountry[country] || defaultCountry) : ''

  const langConcat = langMapped + (countryMapped ? '-' + countryMapped : '')

  return langConcat
}