import { useState, useEffect } from 'react';

const useScrollbarOffset = () => {
    const [style, setStyle] = useState({ '--offset-scrollbar': '0px' });
    const [wasScrollbarVisible, setWasScrollbarVisible] = useState(null);

    useEffect(() => {
        const calculateScrollbarWidth = () => {
            const outer = document.createElement('div');
            outer.style.visibility = 'hidden';
            outer.style.overflow = 'scroll';
            outer.style.width = '50px';
            outer.style.height = '50px';
            document.body.appendChild(outer);

            const inner = document.createElement('div');
            inner.style.width = '100%';
            outer.appendChild(inner);

            const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

            outer.parentNode.removeChild(outer);

            return scrollbarWidth;
        };

        const updateStyle = () => {
            const scrollbarVisible = window.innerWidth > document.documentElement.clientWidth;
            const scrollbarWidth = calculateScrollbarWidth();

            if (wasScrollbarVisible === null) {
                // First render, set initial style
                setWasScrollbarVisible(scrollbarVisible);
            } else if (wasScrollbarVisible && !scrollbarVisible) {
                // Scrollbar was visible, now it's not
                setStyle({ '--offset-scrollbar': `${scrollbarWidth}px` });
            } else if (!wasScrollbarVisible && scrollbarVisible) {
                // Scrollbar was not visible, now it is
                setStyle({ '--offset-scrollbar': '0px' });
            }

            setWasScrollbarVisible(scrollbarVisible);
        };

        updateStyle();

        const resizeObserver = new ResizeObserver(() => {
            updateStyle();
        });

        resizeObserver.observe(document.documentElement);

        return () => {
            resizeObserver.disconnect();
        };
    }, [wasScrollbarVisible]);

    return style;
};

export default useScrollbarOffset;
