@use "source/webComponent/theme" as *;
.root {
  &:hover {
    cursor: pointer;
  }
  .message {
    color: $color_warning;
    text-align: left;
  }
  .error {
    color: $color_warning;
    border: 1px solid $color_border;
    padding: $spacine_px;
    border-radius: $spacing_message_radius;
    background-color: $warmWhite;
    display: flex;
    gap: 8px;
    .icon {
      height: 20px;
      svg {
        height: 12px;
        width: 12px;
        fill: $color_warning;
      }
    }
  }
}