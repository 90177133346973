import React, { useContext, createContext, useState } from 'react'
import cn from 'classnames'
import { OperaContext } from '@/lib/operaFrame/OperaProvider'

import * as styles from './styles.module.scss'

export default ({ className, options, onClick, ...rest }) => {
  const {
    //
  } = useContext(OperaContext)

  const handleSelect = (o, idx) => {
    // trigger parent onClick
    onClick(o, {})
  }

  return (
    <div className={cn(className, styles.root)}>
      <ul>
        {options &&
          options.map((o, idx) => {
            const { option } = o
            return (
              <li key={`options_${idx}`}>
                <button
                  onClick={() => {
                    handleSelect(o, idx)
                  }}
                >
                  {option}
                </button>
              </li>
            )
          })}
      </ul>
    </div>
  )
}
