import React, { useContext, createContext, useState, useEffect } from 'react'
import _ from 'lodash'
import cn from 'classnames'
import DefaultLogo from '@/assets/images/default_logo.png'
import phone from '@/assets/icons/phone-solid.svg'
import iconMessage from '@/assets/icons/message-bubble.svg'
import map from '@/assets/icons/mobile-checkins.svg'
import check from '@/assets/icons/circle-check.svg'

import { GeneralContext } from '@/provider/GeneralProvider'
import useFile from '@/core/hooks/useFile'
import utils from '@/core/utils/helper'
import constants from '@/core/utils/constants'
import useEngagement from '@/core/hooks/useEngagement'

import Api from '@/provider/Api'
import * as styles from './styles.module.scss'

// categories that are reserved for special services won't be displayed
const RESERVED_SERVICE_CATEGORIES = { HT: true, CTRL: true }

export default ({ className, ...rest }) => {
  const {
    zIndex,
    showFacility,
    token,
    labels,
    isPreview
    //
  } = useContext(GeneralContext)

  const { getImage } = useFile()

  const [moreInfo, setMoreInfo] = useState(null)
  const [services, setServices] = useState(null)

  const isShow = showFacility?.org_id

  useEffect(() => {
    if (showFacility?.org_id) {
      doGetFacility(showFacility.org_id)
    } else {
      setMoreInfo(null)
    }
  }, [isShow])

  // if (!isShow) return null

  const doGetFacility = async org_id => {
    const res = (await Api.getFacility(token, org_id, {isPreview})) || null

    // split AllSCodes by category_code, display scode_name (in the future, scode_label)
    if (res?.AllSCodes) {
      const _services = {}
      res.AllSCodes.forEach(scode => {
        const { category_code, scode_name, category_name } = scode
        if (RESERVED_SERVICE_CATEGORIES[category_code]) return
        if (!category_code) {
          console.log('category_code is missing in scode', scode)
          return
        }
        if (!_services[category_code]) {
          _services[category_code] = { category_name, scodes: [] }
        }
        _services[category_code].scodes.push({ scode_name })
      })
      setServices(_services)
    }

    setMoreInfo({ ...showFacility, ...res })
    return
  }

  const {
    org_id,
    logo,
    description,
    name1,
    name2,
    street1,
    street2,
    region,
    city,
    zip,
    latitude,
    longitude,
    country = 'US',
    public_phone,
    phone_hotline,
    phone_hotline2,
    phone_details,
    phone_intake1,
    phone_intake2,
    sms,
    sms_msg,
    sms_details,
    website,
    distance
  } = moreInfo || {}

  const handleRecord = useEngagement(null)

  const hrefWebsite = website
  const hrefPublicPhone = `tel:${formatPhoneNumber(public_phone)}`
  const hrefPhoneHotline = `tel:${formatPhoneNumber(phone_hotline)}`
  const hrefSms = `sms:${formatPhoneNumber(sms)}?body=${sms_msg}`

  const full_address = [street1, street2, city, region, zip]?.filter(a => a).join(', ')
  return (
    <div className={cn(className)}>
      {/* table */}
      <div className={cn(styles.header)}>
        <div>
          <div className={cn(styles.logo_container)}>
            <img src={getImage(logo) || DefaultLogo} alt="logo" />
          </div>
        </div>
        <div className={cn(styles.titleContainer)}>
          <div className={cn(styles.title)}>{[name1, name2].join('\n')}</div>
          {website ? (
            <a onClick={handleRecord(constants.ACTIVITY_TYPES.FAC_EXT_WEBSITE, org_id, hrefWebsite)} className={cn(styles.website)} href={hrefWebsite} target="_blank" rel="noreferrer">
              {labels['lbl_VisitWebsite'] || 'Visit Website'}
            </a>
          ) : null}
        </div>
      </div>
      <div className={cn(styles.body)}>
        {public_phone ? (
          <div className={cn(styles.phone)}>
            <a onClick={handleRecord(constants.ACTIVITY_TYPES.FAC_EXT_PUBLICPHONE, org_id, hrefPublicPhone )} href={hrefPublicPhone}>
              <img className={cn(styles.icon)} src={phone} alt="phone" />
              {public_phone}
            </a>
          </div>
        ) : null}
        {/* {phone_intake1 ? (
          <div className={cn(styles.phone)}>
            <a href={`tel:${formatPhoneNumber(phone_intake1)}`}>
              <img className={cn(styles.icon)} src={phone} alt="phone" />
              {phone_intake1}
            </a>
          </div>
        ) : null}
        {phone_intake2 ? (
          <div className={cn(styles.phone)}>
            <a href={`tel:${formatPhoneNumber(phone_intake2)}`}>
              <img className={cn(styles.icon)} src={phone} alt="phone" />
              {phone_intake2}
            </a>
          </div>
        ) : null} */}
        {phone_hotline ? (
          <div className={cn(styles.phone)}>
            <a onClick={handleRecord(constants.ACTIVITY_TYPES.FAC_EXT_HOTLINE, org_id, hrefPhoneHotline)} href={hrefPhoneHotline}>
              <img className={cn(styles.icon)} src={phone} alt="hotline" />
              {phone_hotline}
            </a>
            {phone_details ? <div>{phone_details}</div> : null}
          </div>
        ) : null}

        {phone_hotline2 ? (
          <div className={cn(styles.phone)}>
            {/* <a href={`tel:${formatPhoneNumber(phone_hotline2)}`}> */}
            <span>
              <img className={cn(styles.icon)} src={phone} alt="hotline" />
              {phone_hotline2}
            </span>
            {/* </a> */}
          </div>
        ) : null}

        {sms ? (
          <div className={cn(styles.phone)}>
            <a onClick={handleRecord(constants.ACTIVITY_TYPES.FAC_EXT_SMS, org_id, hrefSms )} href={hrefSms}>
              <img className={cn(styles.icon)} src={iconMessage} alt="sms" />
              {sms}
            </a>
            {sms_details ? <div>{sms_details}</div> : null}
          </div>
        ) : null}
        {full_address ? (
          <div className={cn(styles.phone_miles)}>
            <div className={cn(styles.address)}>
              <img className={cn(styles.icon)} src={map} alt={labels['lbl_map'] || 'map'} />
              {full_address} {distance ? <>({_.round(distance, 1) || '--'} miles)</> : null}
              <a
                className={cn(styles.navigate)}
                href={`https://www.google.com/maps/search/?api=1&query=${formatAddressForGoogleMaps(
                  [street1, city, region, zip].join(', ')
                )}`}
                target="_blank"
              >
                {labels['lbl_map'] || 'map'}
              </a>{' '}
            </div>
          </div>
        ) : null}
        <br />
        {_.keys(services)?.map((category_code, index) => {
          const { category_name, scodes } = services[category_code]

          return (
            <div key={index} className={cn(styles.services)}>
              <div className={cn(styles.title)}>{category_name}</div>
              <ul>
                {scodes?.map((scode, index) => {
                  return (
                    <li key={index}>
                      <img src={check} />
                      {scode.scode_name}
                    </li>
                  )
                })}
              </ul>
            </div>
          )
        })}
      </div>
    </div>
  )
}

function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) return '#'
  // Remove any non-numeric characters (except the plus sign)
  let formattedNumber = phoneNumber.replace(/[^\d+]/g, '')
  return formattedNumber
}

function formatAddressForGoogleMaps(address) {
  // Replace spaces with plus signs and encode special characters
  return encodeURIComponent(address.trim().replace(/\s+/g, '+'))
}
