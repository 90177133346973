import _ from 'lodash'
import {LINKTYPES} from "./constants"

import constants from '@/core/utils/constants'

/**
 * original purpose is to update sCodes with AND/OR/NOTEXISTS logics
 * then we extend it to update input values
 * then we extend it to redirect to external url
 * @param {*} payloadUpdator
 * @param {*} currentPayload
 * @returns
 */
const processPayload = (payloadUpdator, currentPayload) => {
  if (!payloadUpdator) {
    return
  }

  const {
    // === special action ===
    redirect_url, // jump to external url

    // === variables we want to update ===
    values, // override current user input, if empty {} then delete, if null do nothing
    scode,

    // === the way we update variables ===
    scode_method = constants.scodeMethods.default, // can be "ATTACH"/"REPLACE",
    operation = constants.operations.default // <work with scode> [AND, OR, NOTEXISTS]
  } = payloadUpdator

  if (redirect_url) {
    window.open(option.redirect_url, '_blank')
  }

  const _v = _.cloneDeep(currentPayload) || {}

  // add parameters
  let bucket = 'sCodes'
  switch (operation) {
    case constants.operations.OR:
      bucket = 'sCodes_or'
      break
    case constants.operations.NOTEXISTS:
      bucket = 'sCodes_notexists'
      break
    case constants.operations.AND:
      bucket = 'sCodes'
      break
    default:
      bucket = 'sCodes'
      break
  }

  let currentSCodes = _v[bucket] || []
  switch (scode_method) {
    case constants.scodeMethods.REPLACE:
      currentSCodes = typeof scode === 'string' ? [scode] : scode
      break
    case constants.scodeMethods.ATTACH:
    default:
      // for scode: add scode to the string array, if not already there
      if (scode && !currentSCodes.includes(scode)) {
        currentSCodes.push(scode)
      }
      break
  }

  _v[bucket] = currentSCodes

  /*
    for values: (values is updator, _v.input is original)
    1. if its {}, delete it
    2. if it has key-value pair and value is not empty, override original value
    3. if it has key-value pair and value is empty, delete original value
  */
  if ( typeof values === 'object' && _.isEmpty(values)) {
    _.unset(_v, 'input')
  } else if (typeof values === 'object') {
    _.keys(values).forEach(k => {
      if (values[k]) {
        _.set(_v, `input.${k}`, values[k])
      } else {
        _.unset(_v, `input.${k}`)
      }
    })
  } 

  return _v
}

const extractPhone = mix => {
  if (!mix) return ''
  // let numb = mix.match(/\d/g);
  let numb = mix.match(/\+|\d/g)

  numb = numb?.join('') || ''
  return numb
}

export const validateSteps = (step, k) => {
  const { question, options, action, type, ...rest } = step
  switch (type) {
    case 'SKIP':
    case 'CLEAR':
      if (!action) console.error(`${k}: action is required`)
      break
    case 'LOC_ZIP':
      if (!question) console.error(`${k}: question is required`)
      if (!action) console.error(`${k}: action is required`)
      break;
    case 'RESULTS':
      if (!question) console.error(`${k}: question is required`)
      if (!action) console.error(`${k}: action is required`)
      break
    case 'OPTIONS_ONLY':
      if (!options) console.error(`${k}: options is required`)
      break
    case 'OPTIONS':
    default:
      if (!options) console.error(`${k}: options is required`)
      if (!question) console.error(`${k}: question is required`)
      break
  }
}

export const regexForZips = {
  US: {
    regex: "^[0-9]{5}(-[0-9]{4})?$",
    message: 'Please enter a valid US zip code'
  },
  ID: {
    regex: "^\\d{5}$",
    message: 'Please enter a valid ID zip code'
  },
  PH: {
    regex: "^\\d{4}$",
    message: 'Please enter a valid PH zip code'
  }
}

export const getLinktype = (link) => {
  const reg = new RegExp("^(?:[a-z]+:)?//", "i");

  if (link?.toLowerCase().lastIndexOf(".pdf") != -1) {
    return LINKTYPES.PDF;
  }

  if (link?.toLowerCase().indexOf("mailto:") === 0) {
    return LINKTYPES.MAIL;
  }

  if (link?.toLowerCase().indexOf("tel:") === 0) {
    return LINKTYPES.PHONE;
  } else if (reg.test(link)) {
    return LINKTYPES.EXTERNAL;
  } else if (link?.indexOf("/") === 0) {
    return LINKTYPES.ABSOLUTE;
  } else {
    return LINKTYPES.RELATIVE;
  }
};

export default {
  extractPhone,
  regexForZips,
  processPayload
}
