import React, { useContext, useEffect, createContext, useState } from 'react'
import _ from 'lodash'
import operaUtils from '@/lib/operaFrame/operaUtils'
import constants from '@/core/utils/constants'
import { OperaContext } from '@/lib/operaFrame/OperaProvider'

const CHARACTER = constants.characters.USER
/**
 * activaly triggered by user input (different than non-human characters)
 * @param {} props
 * @returns
 */
const useUser = props => {
  const {
    onSpeakLine,
    tree,
    setStage,

    onClaimPerformance,
    onPerformance,
    onProcessPayload,
    //
  } = useContext(OperaContext)

  /* 
    TODO: in the future, user is not an actor. actor is only AI/system characters
    user can send message as they want, and the system will process it
  */
  const onProcessOption = (optionObj, input = {}, holding = false) => {
    const performance = onClaimPerformance(CHARACTER)
    if (!performance) {
      return false
    }
    if (!holding) {
      onPerformance()
    }
    if (optionObj?.option) {
      onSpeakLine(CHARACTER, {
        chatMessage: optionObj.option
      })
    }

    if (optionObj?.action) {
      // if currentActor is cueActor, cue the next step
      setStage(prev => {
        const _v = _.cloneDeep(prev)

        _.set(_v, 'behavior.currentActor', CHARACTER)

        const currentPayload = _.get(_v, 'userPayload', {})
        const _userPayload = onProcessPayload(optionObj, currentPayload)        

        _.set(_v, 'userPayload', _userPayload)
        _.set(_v, 'currentStep', performance?.step)
        _.set(_v, 'nextStep', optionObj?.action)
        return _v
      })
    }

    /* 
      prevent director cue next character right away
      in case user has multiple messages and want to show them one bubble after another
      [20240729] currently we dont have this case. we always do "bot -> user" process
    */

  }

  const onProcessInput = (input = {}, message, holding = false) => {
    const performance = onClaimPerformance(CHARACTER)
    if (!performance) {
      return false
    }
    if (!holding) {
      onPerformance()
    }
    const { step } = performance
    const { action } = tree[step] || {}

    if (input) {
      onSpeakLine(CHARACTER, {
        chatMessage: message
      })

      setStage(prev => {
        const _v = _.cloneDeep(prev)

        _.set(_v, 'behavior.currentActor', CHARACTER)

        const currentPayload = _.get(_v, 'userPayload', {})
        const payloadUpdator = {
          values: input
        }

        const _userPayload = onProcessPayload(payloadUpdator, currentPayload)
        _.set(_v, 'userPayload', _userPayload)
        _.set(_v, 'currentStep', performance?.step)
        _.set(_v, 'nextStep', action)
        return _v
      })
    }
  }

  return {
    onProcessOption,
    onProcessInput
  }
}

export default useUser
