// src/wr-web-component.js
import 'custom-event-polyfill'
import React, { useRef, useImperativeHandle, forwardRef } from 'react'
import ReactDOM from 'react-dom'
import reactToWebComponent from 'react-to-webcomponent'
import RAI from './webComponent/HAI.js'

// Access the current script tag
const currentScript = document.currentScript

// Read an attribute from the script tag (e.g., data-token)
const initLanguage = currentScript.getAttribute('languagecode')
const initAccessCode = currentScript.getAttribute('accesscode')
const initZIndex = currentScript.getAttribute('z-index')
const initDataId = currentScript.getAttribute('trigger-id')
const initUserAvatar = currentScript.getAttribute('useravatar')
const initCookieOff = currentScript.getAttribute('cookie-off')
const initIsPreview = currentScript.getAttribute('preview')

// Function to dynamically load CSS
const loadCSS = href => {
  if (!document.querySelector(`link[href="${href}"]`)) {
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = href
    document.head.appendChild(link)
  }
}

const RaiWebComponent = reactToWebComponent(
  forwardRef((props, ref) => {
    return (
      <RAI
        ref={ref}
        {...{
          initLanguage,
          initAccessCode,
          initZIndex,
          initDataId,
          initUserAvatar,
          initCookieOff,
          initIsPreview
        }}
        {...props}
      />
    )
  }),
  React,
  ReactDOM,
  {
    props: {
      // NOTE: web component only supports lower case
      id: 'string',
      // NOTE: dont use language, it is reserved will cause conflict (crush without error message)
      languagecode: 'string',
      accesscode: 'string',
      ['z-Index']: 'string',
      ['trigger-id']: 'string',
      useravatar: 'string',
      ['cookie-off']: 'boolean',

      // internal use
      triggerer: 'boolean'
    }
  }
)

class ExtendedHaiWebComponent extends RaiWebComponent {
  connectedCallback() {
    super.connectedCallback()
    const scriptUrl = new URL(currentScript.src)
    const cssUrl = new URL('rivara-ai.css', scriptUrl)
    loadCSS(cssUrl.href)

    // remove styles from the html element rivara-ai
    this.style.display = ''
  }

  update(props) {
    // update attributes of web component
    Object.keys(props).forEach(key => {
      this.setAttribute(key, props[key])
    })

    // update trigger to trigger rerender
    let triggerer = this.getAttribute('triggerer') === 'true'
    this.setAttribute('triggerer', !triggerer)
  }
}

customElements.define('rivara-ai', ExtendedHaiWebComponent)

// if there is no rivara-ai element in the DOM, create one and attach to body
if (!document.querySelector('rivara-ai')) {
  const rai = document.createElement('rivara-ai')
  // default style is display: none
  rai.style.display = 'none'

  // append below of script tag
  if (currentScript && currentScript.parentNode) {
    currentScript.parentNode.insertBefore(rai, currentScript.nextSibling)
  } else {
    document.body.appendChild(rai)
  }
}
