// src/ChatButton.js
import React, { useState, useImperativeHandle, forwardRef, useEffect, useContext } from 'react'
import _ from 'lodash'
import { GeneralProvider } from './provider/GeneralProvider'
import ChatboxContainer from './components/template/ChatboxContainer'

const RAI = forwardRef(
  (
    {
      initAccessCode = '',
      initLanguage = '',
      initDataId = '',
      initZIndex = '',
      initUserAvatar = '',
      initCookieOff = false,
      initIsPreview = false,
      // ==== custom updates from outside ====
      languagecode: customLanguage = '',
      accesscode: customAccessCode = '',
      useravatar: customUserAvatar = '',
      ['cookie-off']: customCookieOff = false,
      // internal use
      triggerer = false
    },
    ref
  ) => {
    const handleClick = () => {
      setShow(prev => !prev)
    }

    const [show, setShow] = useState(false)
    useEffect(() => {
      if (initDataId) {
        const triggerButton = document.querySelector(`#${initDataId}`)
        if (triggerButton) {
          triggerButton.addEventListener('click', handleClick)

          // Cleanup function to remove the event listener
          return () => {
            triggerButton.removeEventListener('click', handleClick)
          }
        }
      }
    }, [initDataId])

    // if no access code, assume its turned off
    // if (!customAccessCode || !initAccessCode) {
    //   return null
    // }

    return (
      <GeneralProvider
        {...{
          initZIndex,
          initAccessCode,
          initLanguage,
          initUserAvatar,
          initCookieOff,
          initIsPreview,
          // ==== custom updates from outside ====
          triggerer,
          customLanguage,
          customAccessCode,
          customUserAvatar,
          customCookieOff,
          show, 
          setShow
        }}
      >
        <ChatboxContainer ref={ref} {...{ initDataId }} />
      </GeneralProvider>
    )
  }
)

export default RAI
