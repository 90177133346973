@use "source/webComponent/theme" as *;

.flag {
  height: 16px;
  object-fit: contain;
  border: 1px solid $color_background;
}

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  .header {
    height: 60px;
    margin-bottom: 10px;
    border-bottom: 1px solid $color_border;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2px;
    padding: 0px $spacine_px * 2;
    .headerButton {
      cursor: pointer;
      margin-bottom: -3px;
      @include buttonClean();
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $fontBody;
      border-bottom: 5px solid transparent;
      transition: all 0.2s ease-in-out;
      gap: $spacine_px;
      height: 60px;
      &.active {
        border-bottom: 5px solid $color_primary;
        transition: all 0.2s ease-in-out;
      }

      &:hover {
        border-bottom: 5px solid $color_alternative;
        transition: all 0.2s ease-in-out;
      }
    }
  }
  .body {
    flex: 1;
    // note: let lock work of outside
    // overflow-y: auto;
  }
}

.tabActive {
  display: block;
}

.tabInactive {
  display: none;
}

.listContainer {
  padding: $spacine_px;
  display: flex;
  flex-direction: column;

  // max-width: 380px;
  margin: auto;

  .item {
    font-size: $fontBody;
    padding: 12px 20px;
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border-radius: $spacing_borderradius_sm;

    .itemText {
      color: $color_textLight;
      display: flex;
      align-items: center;
      gap: $spacine_px;
    }

    &:hover {
      background-color: $color_primary;
      transition: all 0.2s;
      .itemText {
        color: $color_background;
      }
    }
    .itemIcon {
      display: none;
    }
    transition: all 0.2s;
  }

  .item.active {
    .itemText {
      font-weight: 600;
      color: $color_black;

      span {
        border-bottom: 1px solid $color_textLight;
      }
    }
    .itemIcon {
      display: flex;
    }

    &:hover {
      .itemText {
        color: $color_background;
        border-bottom: 1px solid $color_background;
      }
    }
  }
}

.itemIcon {
  height: 20px;
  width: 20px;
  svg {
    fill: $color_alternative;
  }

  mask-size: cover;
  mask-repeat: no-repeat;
}

.languageIcon {
  display: flex;
  height: 20px;
  fill: $color_primary;
}
