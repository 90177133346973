import React, { useContext, useEffect, createContext, useState } from 'react'
import _ from 'lodash'
import constants from '@/core/utils/constants'
import operaUtils from '@/lib/operaFrame/operaUtils'
import Api from '@/provider/Api'
import { OperaContext } from '../OperaProvider'

const CHARACTER = constants.characters.SYSTEM

/**
 * passively listens for stage changes
 * system usually interacts with the backend then send message to chatbox
 * @param {*} props
 */
const useSystem = props => {
  const {
    tree,
    stage,
    setStage,
    onSpeakLine,
    onCancelSpeakLine,

    waitingQueue,
    onClaimPerformance,
    onPerformance,
    onProcessPayload,
    //

    onFetchResults
  } = useContext(OperaContext)

  useEffect(() => {
    if (tree) {
      const performance = onClaimPerformance(CHARACTER)
      if (performance) {
        process(stage, tree, performance)
      }
    }
  }, [stage, tree, waitingQueue])

  const process = async (stage, tree, performance) => {
    onPerformance(true)
    const { userPayload = {}, botPayload = {}, systemPayload, behavior = {} } = stage
    const { step } = performance

    // check if we need to clear userPayload (entry or step type is clear)
    let isToClearUserPayload = false
    const { type, action } = tree[step] || {}
    if (step === constants.stepForSystem.ENTRY || type === constants.stepTypes.CLEAR) {
      isToClearUserPayload = true
    }

    setStage(prev => {
      const _v = _.cloneDeep(prev)
      _.set(_v, 'behavior.currentActor', CHARACTER)

      // clear sCodes, input
      if (isToClearUserPayload) {
        delete _v.userPayload
        delete _v.botPayload
        delete _v.systemPayload
      } else {
        const currentPayload = _.get(_v, 'userPayload', {})
        // process all step object special fields (redirect url, fixed variables, etc)
        const _userPayload = onProcessPayload(tree[step], currentPayload)
        _.set(_v, 'userPayload', _userPayload)
      }

      _.set(_v, 'currentStep', step) 
      if (action) {
        _.set(_v, 'nextStep', action)
      }
      return _v
    })

    /*
    === 
    fill in the panel. must be after onPerformance because async. 
    If we call onPerformance after it, before the API finish, system thinks its not picked, then task can be picked again
    === 
    */
    switch (type) {
      case constants.stepTypes.RESULTS:
        // show panel, get id, then fill the panel with data
        const slId = onSpeakLine(CHARACTER, { isMessageLoading: true }, type)
        try {
          const res = (await onFetchResults(userPayload)) || null
          onSpeakLine(CHARACTER, { data: res }, type, slId)
        } catch (error) {
          /* 
          NOTE: 
          because its not a global level error, its only invalid content
          so we treat it as part of conversation. show in system bubble, not throwing to StageManager
          */
          const message = _.get(error, 'response.data.message', 'An error occurred')
          onSpeakLine(CHARACTER, { errorMessage: message.toString() }, type, slId)
        }
        break
      case constants.stepTypes.SKIP:
      case constants.stepTypes.CLEAR:
        break
      default:
        // if we want to make it generic we can put RESULTS here, instead of RESULTS, we dynamically pass the type
        break
    }

    onPerformance(false)
  }
}

export default useSystem
