import React, { useContext, useEffect, createContext, useState } from 'react'
import _ from 'lodash'

import constants from '@/core/utils/constants'
import { OperaContext } from '../OperaProvider'

const CHARACTER = constants.characters.BOT

/**
 * passively listens for stage changes
 * @param {*} props
 */
const useBot = (props) => {
  const {
    tree,
    stage,
    setStage,
    onSpeakLine,

    waitingQueue,

    onClaimPerformance,
    onPerformance,
    //
  } = useContext(OperaContext)

  useEffect(() => {
    if (tree) {
      const performance = onClaimPerformance(CHARACTER)
      if (performance) {
        // await 1s then process
        process(stage, tree, performance)
      }
    }
  }, [stage, tree, waitingQueue])

  const process = async (stage, tree, performance) => {
    onPerformance(true)
    const { step } = performance
    // current step
    const { question, html, message, action } = tree[step] || {}

    const currentType = tree[step]?.type

    // TODO: in the future if need backend data, should use Promise.all
    // await 1s before speaking
    await new Promise(resolve => setTimeout(resolve, constants.TIME_BREAK_BETWEEN_MESSAGE))

    // if no message. BOT will skip it.
    /*
      here is redundant setting. both question and html will be displayed.
      reason is to support legacy package react-simple-chatbot. we no longer needs it
    */
    let displayMessage = message || question || html || null
    if (displayMessage) {
      onSpeakLine(CHARACTER, { chatMessage: displayMessage}, currentType)
    }
    
    setStage((prev) => {
      const _v = _.cloneDeep(prev)
      _.set(_v, 'behavior.currentActor', CHARACTER)

      /* 
        original reason we store 2 steps-- when awaiting user input. we need to:
        InputZone component needs to grab variables from current step
        director still need to know know what next step to go
        ======
        we keep updating 2 steps, but until director pick it up, it will not be processed. 
        so only latest update will be processed 
        reason is the "action" can be from different sources. (user select option / "SKIP" step)
      */
      _.set(_v, 'currentStep', step) 
      if (action) {
        _.set(_v, 'nextStep', action)
      }
      return _v
    })
    onPerformance(false)
  }
}

export default useBot
