@use "source/webComponent/theme" as *;

.show {
  left: 0;
  transition: all 0.2s;
  opacity: 1;
}

.hide {
  left: -100%;
  opacity: 0;
  transition: all 0.2s;
}

.root {
  background-color: #00000090;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $warmWhite;

  .ModalScrollContainer {
    overflow-x: hidden;
    overflow-y: auto;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    padding: $spacine_px;
    box-sizing: border-box;
  }
}