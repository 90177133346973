@use 'source/webComponent/theme' as *;

.root {
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: $spacine_px_sm;
    padding: 0;
    list-style-type: none;
    justify-content: flex-end;

    li {
      list-style: none;
      padding: 0;
      button {
        @include animatedElement(bottom);
        background-color: rgb(250, 248, 242);
        border: 1px solid rgb(0, 38, 119);
        color: rgb(0, 38, 119);
        cursor: pointer;
        padding: 8px 12px;
        border-radius: 22px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
        font-size: $fontBody;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}
